body {
  padding: 80px 10vw;
  background-color: #f4f1ef;
  font-family: Plusjakartasans, sans-serif;
  color: #343434;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  letter-spacing: -1px;
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

a {
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #343434;
  text-decoration: none;
}

.grid {
  display: -ms-grid;
  display: grid;
  margin-bottom: 160px;
  grid-auto-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 120px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.card-wrapper {
  border-top: 1px solid #000;
}

.entry-wrapper {
  width: 100%;
  margin-bottom: 24px;
}

.entry-wrapper:hover {
  color: #bc5911;
}

.hr-40px {
  width: 40px;
  border-top: 1px solid #d3d3d3;
}

.entry-title {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 28px;
}

.entry-title.award {
  margin-top: 0px;
  margin-bottom: 0px;
}

.entry-date {
  margin-right: 24px;
  font-size: 11px;
  line-height: 11px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.entry-date.right {
  text-align: right;
}

.entry-date.award {
  width: 48px;
  min-width: 48px;
  margin-top: 6px;
}

.heading {
  font-weight: 600;
}

.hero-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 160px 120px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.quote {
  margin-bottom: 16px;
  font-family: 'Cardo', sans-serif !important;
  font-size: 26px;
  line-height: 34px;
  font-style: italic;
  font-weight: 400;
}

.quote-wrapper {
  margin-left: 60px;
}

.image-wrapper {
  overflow: hidden;
  width: 192px;
  height: 260px;
  min-width: 192px;
  border-radius: 100px;
}

.social-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: #343434;
}

.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-icon {
  margin-left: 32px;
  padding: 8px;
  color: #343434;
}

.social-icon:hover {
  color: #bc5911;
}

.award-text-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.award-list-wrapper {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 80px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button {
  margin-top: 80px;
  border: 1px solid #343434;
  background-color: transparent;
  -webkit-transition: background-color 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, color 300ms ease;
  color: #343434;
  font-size: 18px;
  line-height: 20px;
}

.button:hover {
  border-style: solid;
  border-color: transparent;
  background-color: #bc5911;
  color: #f5f1ef;
}

@media screen and (max-width: 991px) {
  .grid {
    grid-column-gap: 60px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .hero-wrapper {
    margin-right: 0px;
    margin-left: 0px;
  }

  .award-list-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  body {
    padding: 40px 20px;
  }

  .grid {
    margin-bottom: 120px;
    grid-row-gap: 60px;
  }

  .heading {
    font-size: 40px;
    line-height: 40px;
  }

  .hero-wrapper {
    margin-top: 40px;
    margin-bottom: 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .quote {
    margin-top: 24px;
    font-size: 24px;
    line-height: 32px;
  }

  .quote-wrapper {
    margin-left: 0px;
  }

  .social-wrapper {
    margin-top: 24px;
  }

  .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .social-icon {
    margin-right: 16px;
    margin-left: 16px;
  }
}

#w-node-_25beaf8c-0195-3a33-75a2-3b32d8744a7b-ec55dd10 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_48c0441c-d31f-99f6-8672-5a434d526972-ec55dd10 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f1ad64bb-778c-ee94-d760-7e85e64e702b-ec55dd10 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2820abbc-abf1-20cb-cc87-4c2c2a3813b5-ec55dd10 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7036696f-61bd-cc66-2594-ac02e9759581-ec55dd10 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4bf17cf2-b0f9-816c-c06f-163e8d3dd06b-ec55dd10 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_47f25a8b-a5cb-8708-0c36-26865389a47c-ec55dd10 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_434e69a5-a657-429e-1c4c-572245bdf456-ec55dd10 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  -ms-grid-column-align: start;
  justify-self: start;
}

@media screen and (max-width: 991px) {
  #w-node-_47f25a8b-a5cb-8708-0c36-26865389a47c-ec55dd10 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_47f25a8b-a5cb-8708-0c36-26865389a47c-ec55dd10 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@font-face {
  font-family: 'Plusjakartasans';
  src: url('./fonts/PlusJakartaSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartasans';
  src: url('./fonts/PlusJakartaSans-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartasans';
  src: url('./fonts/PlusJakartaSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartasans';
  src: url('./fonts/PlusJakartaSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cardo';
  src: url('./fonts/Cardo-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}